const self = {
    state(){
        return {
            hero:{
                img:'self/self-1.png',
                title:'Explore The Best Self Custody Wallet Embedded With Enterprise Level Security and Compliance',
                subtitle:'Introducing A Sophisticated Decentralized Digital Asset Custody Extension',
                btn:{
                    title:'Access Decentralised Custody',
                    url:'#'
                }
            },
            pair1:{
                title:"What is Self-Custody?",
                subtitle:[
                    'The origin and presence of Crypto Asset Custody revolve around the process of trusting systems and saving private keys locally, and not hand-it-over to a third party.',
                    'Multiple facets of interacting with centralized and decentralized protocols have always prioritized faster transaction processing and smooth user interface rather than the security of the asset itself, which solidifies the practical notion of implementing a self-custody wallet industrially.',
                    'For digital assets to jump over the bottlenecks in the architectural vulnerability, secure and local digital asset custody needs to be integrated into the back end of both custodial and non-custodial wallets.'
                ],
                btn:{
                    title:'Multi-Signature Wallet',
                    url:'#'
                }
            },
            pair2:{
                title:"Why opt for Self-Custody?",
                subtitle:[
                    'The concept of self-custody of assets has emerged not just for individuals to have complete autonomy over their assets, but for institutions who offer trading, lending, borrowing, staking, options, derivatives, and more fundamental use cases, giving an actual meaning to digital assets custody.',
                    'With millions or billions of assets in custody, self-custody becomes imperative to safeguard customer and institution assets in a robust setting.',
                    'Self Custody solutions have evolved in their functionality with the likes of:'
                ],
                btn1:{
                    title:'Multi-Party Computation',
                    url:'#'
                },
                btn2:{
                    title:'Multi-Signature Wallet',
                    url:'#'
                },

            },
            pair3: {
                title:"How does Tennet simplify Self-Custody?",
                subtitle:[
                    'Expanding The Feasibility Of Decentralized and Distributed Custody Wallets For Web3 Native Teams',
                    'Tennet’s Hybrid Model Creates An Multi-Dimensional Setup To Offer Complete Control Over Assets, Secure Infrastructure and Compliance Compatibility',
                ],
            },
            pair4:{
                title:"Why choose Tennet?",
                subtitle:"Find A Repository Of Prominent Feature Stack To Achieve Automation Efficiency, Operational Excellence, and Cost Optimization,",
                choose: [
                    "A combination of Hot/Warm and Cold Wallet Configuration Based On Your Assets Usage Criteria",
                    "A Plug-and-Play Architecture To Support Large-Scale Wallet Operations",
                    "Interoperability Check With Support For 20+ EVM Chains and 1500+ Assets ",
                    "Insurance coverage of $50 Million by Lloyds Bank",
                    "An Ecosystem Wide Integration of the Tennet Valuts App For Maximum DeFi Support",
                    "Wallet key cards to ensure wallet recovery along with specialized wallet recovery training modules",
                    "A dedicated concierge-level onboarding service to aid organizations and teams in shifting their entire custody safely",
                    "Mobile-First Approach To Help Track Assets, Create Custom Policies, Sign Transactions, Check Balances, Revoke Permissions and Add Signatories on-the-go",
                    "A rich developer infrastructure with extensive SDK/API integration capabilities",
                    "Automation integration built into MPC wallet to highlight auto-sweep and wallet-refill functionality",
                ],
            },
            pair5:{
                title:"How do we maintain asset security?",
                subtitle:[
                    'Our security parameters are structured with hardcore industry-grade standards and protocols to reduce self-custody vulnerabilities, anti-countering single point-of-failures and recovery mechanisms',
                    'We help institutions implement the most practical and effective security integrations to protect their assets from a range of threats including cyber attacks, key misplacement, regulation-based shutdowns, and inefficient practices.',
                    'Through our interlinked wallet ecosystem from Hot Wallets + Warm Wallets (Refill Wallet) linked to Cold Wallets or mainstream HSM devices (Trezor, Ledger) we maintain a secured flow of assets between them at all times. Platform-level safety is attained through our security certifications ISO 27001, SOC Type 1 & 2 and the most prestigious CCSS QSP Level-3, which makes Tennet only the second platform to have such elaborate industry-compliant audit certifications.'
                ],
                btn:{
                    title:"View Our Security Metrics",
                    url:"#",
                    
                }
            }
        }
    }
}

export default self
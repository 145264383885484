const career = {
  state() {
    return {
      img: "career/benefit/career.svg",
      title: "Join our team!",
      subtitle: [
        "We work with an exceptional bunch of people, the latest tech, cutting-edge tools, and leading companies across industries to create value for our customers, partners, team members, and larger communities.",
        "Let’s find solutions to the world’s biggest crypto challenges, together!",
      ],
      enable: {
        title: "We Enable",
        subtitle:
          "Tennet is where crypto-native citizens live. We wish to make digital assets accessible, simple, transparent, and secure.",
      },
      pairs: [
        // {
        //   img: "career/ambitions.svg",
        //   title: "Ambitious",
        //   subtitle: [
        //     "We are an ambitious team of individuals who are chasing the Big Hairy Audacious Goals (BHAG) and we work with full authority. There are no right or wrong decisions, but only timely or late decisions.",
        //     "Thus, we execute with great speed. We think, we create, we deliver, and we drive innovation.",
        //   ],
        //   reverse: 1,
        // },
        {
          img: "career/emotionally-intelegent.svg",
          title: "Emotionally Intelligent",
          subtitle: [
            "Successful companies are built on strong and positive emotions, and we aim to drive this internally. Emotional intelligence leads to impactful results.",
            "There may be tough days or even months, but we make sure we pull each other through a great and successful quarter. As a team, we celebrate even the small wins together.",
          ],
          reverse: 1,
        },
        {
          img: "career/goal-oriented.svg",
          title: "Goal-Oriented",
          subtitle: [
            "We focus on the goal, and we ensure that the journey is fruitful too. We learn from each other’s experiences, whether success or failure, each chapter adds to a takeaway which is a useful lesson.",
            "We share, express, and aim to achieve the planned outcome together as a team.",
          ],
          reverse: 0,
        },
        {
          img: "career/appreciative.svg",
          title: "Appreciative",
          subtitle: [
            "As a team, we are thankful to have each other’s back. We are kind when we give feedback, and we take feedback positively. Tennet's motivating culture to showcase one’s work, and share appreciation, sets us apart from others. ",
          ],
          reverse: 1,
        },
        {
          img: "career/process-driven.svg",
          title: "Process Driven",
          subtitle: [
            "Seamless workflow between the team is the secret ingredient. We take ownership of our work, and ensure its delegated with due diligence and automated in time. ",
          ],
          reverse: 0,
        },
      ],
      culture:{
          img: "career/benefit/our_culture.svg",
          title: "Our Culture",
          subtitle: [
            "Team Tennet is a bunch of talented crypto enthusiasts, who bring knowledge, experience, and passion to the table. We are here to build a healthy work environment where individuals feel free to explore their potential. While we create history in the digital asset security space, you get to work with progressive people, who deeply care for each other while taking full ownership of the work, and focus to succeed as a team. Laying the trust in our in-house members, we follow a transparent culture to share financials, business goals, and strategies with the team. ",
          ],
          reverse: 1,
      },
      quote1: {
        title:
          "We are passionate about empowering our employees to take ownership of their careers and achieve their goals.",
      },
      growth: {
        title: "We Grow Together",
        subtitle:
          "This is the best time to join Tennet. We’re building a team that is shaping the future of secured digital transactions. This learning experience will not just enhance your professional profile, but also add value to your personal growth.",
        benefit: [
          // {
          //   img: "benefit-1.png",
          //   title: "‍Remote Working",
          //   subtitle:
          //     "‍Pandemic or no pandemic, we are a remote-only organization that offers the highest level of flexibility to its employees.",
          // },
          {
            img: "benefit-2.png",
            title: "‍Best in Class Salary",
            subtitle:
              "‍At Tennet, we appreciate the good talent, and ensure that our employees are compensated with the salary brackets that are best in the industry.",
          },
          
        
          {
            img: "benefit-6.png",
            title: "‍Self-Managed Paid Leaves",
            subtitle:
              "‍Say bye to leave without pay! Yes, there are no limits to the number of leaves you can take. We want employees to be responsible enough to strike a good work-life balance.",
          },
          {
            img: "benefit-8.png",
            title: "‍Health Insurance",
            subtitle:
              "‍We care for our beloved Tenneters. Our health insurance provides comprehensive coverage to our employees and their spouses and dependent children.",
          },
          {
            img: "benefit-7.png",
            title: "Tennet Book Club",
            subtitle:
              "Tenneters believe in the power of reading. Reading habits can fetch maximum ROI on time and money invested. You can raise a request for a book, and it will be delivered to your doorstep. No need to return or share, it’s yours to keep forever.",
          },
          {
            img: "benefit-10.png",
            title: "‍Lifetime free access",
            subtitle:
              "Tennet employees receive lifetime free access to the Tennet Vaults platform even after they decide to move on. Employees will additionally get 2 Trezors at zero cost as a part of their onboarding. You can enjoy the benefits of the software as well as the hardware for free.",
          },
          {
            img: "benefit-4.png",
            title: "‍Flexi-hours",
            subtitle:
              "We don’t like to micromanage. We believe it’s impolite to ask employees to punch in & out or follow similar activities to track the number of working hours. We trust our employees to get the job done and achieve the identified goals with excelent ways.",
          },
          {
            img: "benefit-11.png",
            title: "Devices & Subscriptions",
            subtitle:
              "‍Get the best devices and tools you need to deliver your work. We provide the best subscriptions to ensure the highest levels of work efficiency.",
          },
          {
            img: "benefit-12.png",
            title: "‍Health Challenge",
            subtitle:
              "Earn money to stay fit. You get a $300 cash reward per quarter to complete the Tennet Challenge! • Run 5kms in 30 mins • 50 pushups at one go",
          },
          {
            img: "benefit-9.png",
            title: "‍Financial Wellness",
            subtitle:
              "‍Financial independence & freedom can do wonders for any individual. At Tennet, we have a financial wellness partner who will enable every employee financial goals.",
          },
        ],
      },
    };
  },
};

export default career;

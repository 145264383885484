import hero_lottie from "@/assets/lottie/animation_lkqmndkb.json";

export default {
  state() {
    return {
      hero: {
        lottie: hero_lottie,
        title2:
          "#1 DIGITAL ASSET CUSTODY IN INDONESIA",
        title1a: "OFFICIAL INDONESIA CRYPTO",
        title1b: "CUSTODY",
        subtitle:
          "Tennet Depository helps crypto exchanges to stored and managed their crypto assets",
        license: {
          title: "Licensed by BAPPEBTI",
          img: "logo.webp",
        },
      },
      partner: {
        partner: {
          title: "Our Partner",
          subtitle:
            "Tennet Depository helps crypto exchanges to stored and managed their crypto assets",
        },
        media: {
          title: "Media/Press Release",
          subtitle:
            "Tennet Depository helps crypto exchanges to stored and managed their crypto assets",
        },
      },
      education: [
        {
          thumbnail: "edu1.webp",
          title:
            "Crypto Custody Solutions: Need of the Hour as Crypto Adoption Gains Traction",
          meta: "With the mass adoption of crypto assets underway, institutional investors, enterprises, and high net-worth",
        },
        {
          thumbnail: "edu2.webp",
          title: "What are BEP2 tokens?",
          meta: "Navigating the Binance (BNB) ecosystem can be difficult. Whether you aim to trade, transact, or establish digital",
        },
        {
          thumbnail: "edu3.webp",
          title: "Difference between Crypto Wallet and Exchange",
          meta: "Sometimes it gets harder for new users to understand the fundamental difference between an exchange and a wallet",
        },
      ],
      custody: {
        image: "vector-custody_1x.webp",
        title: "Indonesia's First Crypto Asset Custody Company",
        subtitle:
          "Tennet Depository Indonesia has been fully committed to becoming the first choice as a trusted crypto asset custodian and security protector in Indonesia. With an innovative twist, we make managing the storage of your valuable assets easier and safer. Security is no longer a concern, as we take extra steps with modern technology and a team of experts ready to safeguard your valuable assets. With Tennet Depository Indonesia, your assets are safe and secure, while you focus on living life.",
        reverse: 0,
      },
      cold: {
        image: "vector_product0_1x.webp",
        title:
          "Tennet Depository Indonesia Cold Wallet Crypto - For All Your Crypto Assets.",
        subtitle:
          "The Tennet Depository cold wallets are like super-safe vaults for your crypto assets. They're built with top-notch security to give you full control over your valuable digital coins. Imagine a high-tech fortress where every nook and cranny is designed with your assets' safety in mind. We're talking cutting-edge encryption, fancy authentication processes, and even smart systems that can spot and stop any suspicious activity. It's like having a team of digital bodyguards watching over your stuff 24/7. And the best part? You're the boss here. Your crypto assets are truly yours to command. These cold wallets aren't just a bunch of fancy tech—they're a promise that your assets are locked up tight and safe from any potential threats.",
        reverse: 0,
      },
      products: [
        {
          image: "vector_product1_1x.webp",
          title: "Crypto Custody",
          subtitle:
            "Crypto custody refers to the safekeeping, management, and storage of digital assets, on behalf of individuals, businesses, or other entities. It involves securely storing private keys, which are essential for accessing and controlling these assets on blockchain networks.",
          reverse: 1,
        },
        // {
        //   image: "vector_product5_1x.webp",
        //   title: "Transaction Settlement",
        //   subtitle:
        //     "Transaction settlement refers to the process of finalizing and recording a transaction's details, ensuring that the involved parties exchange value and that the transaction is securely and immutably recorded. In traditional financial systems, settlement often involves intermediaries, delays, and potential vulnerabilities. Blockchain offers a streamlined alternative.",
        //   reverse: 0,
        // },
      ],
      faqs: {
        image: require(`@/assets/vector/faqs.svg`),
        title: "Frequently asked questions",
        faqs: [
          {
            title: "What is Tennet?",
            description:
              "Tennet is Indonesia Official Crypto Custody and licensed by BAPPEBTI to stored and managed exchanges’ crypto assets securely.",
          },
          {
            title: "How does Tennet work?",
            description:
              "Tennet is integrated with crypto central exchange (CFX) which records crypto exchange transactions and with PT KBI (Kliring Berjangka Indonesia) which settled the transactions. Subsequently, the crypto assets are stored and managed by Tennet.",
          },
          {
            title:"Who needs Tennet?",
            list:true,
            description:[
              {
                title:"Crypto Exchanges",
                subtitle:"Tennet is here to help crypto exchanges that need advanced security on storing and managing their crypto assets."
              },
              {
                title:"Crypto Trader and Investor",
                subtitle:"Tennet can be a safe place for crypto trader and investor, knowing their crypto assets are secured with solid security system and comply to the regulation."
              },
            ]
          },
          {
            title: "How safe is Tennet?",
            description:
              "Beside being licensed by BAPPEBTI, Tennet is also proven to have secured, integrated, and effective cyber management system through ISO/IEC 27001:2022 Certification.",
          },
        ],
      },
    };
  },
};

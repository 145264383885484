const custody = {
state(){
    return {
        custody: {
            icon:`<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-9 h-9 text-blue-500 mr-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21 7.5l-2.25-1.313M21 7.5v2.25m0-2.25l-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3l2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75l2.25-1.313M12 21.75V19.5m0 2.25l-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25" />
          </svg>
          `,
            title: `Crypto Custody Services`,
            subtitle:`Tennet offers a comprehensive custody platform for the custody of cryptocurrencies, and digital assets through our cold, hot or MPC wallet solutions. This integrated technology ensures asset availability to Tennet's partners including financial institutions, exchanges and other counterparty partners where a client has an open account.`,
            custody:{
                // image:`vector-custody.png`,
                icon:``,
                title:`Crypto and Digital Asset Custody`,
                subtitle:`In the rapidly evolving world of cryptocurrencies, safeguarding your digital assets is paramount. At Tennet, we understand the importance of reliable and secure crypto custody services to protect your investments and provide you with peace of mind.`
            },
            list:[
                {
                    title:"Cutting-Edge Security",
                    subtitle:"Our state-of-the-art security measures are designed to thwart even the most sophisticated threats. From multi-signature wallets to advanced encryption protocols, we employ industry-leading practices to ensure the safety of your digital assets.",
                },
                {
                    title:"24/7 Monitoring",
                    subtitle:"Our team of experts monitors your assets around the clock. Any suspicious activity is immediately addressed to prevent unauthorized access.",
                },
                {
                    title:"Regulatory Compliance",
                    subtitle:"We adhere to strict regulatory standards, ensuring that your assets are in compliance with the latest legal requirements. Your peace of mind is our top priority."
                },
                {
                    title:"User-Friendly Interface",
                    subtitle:"Managing your digital assets has never been easier. Our user-friendly platform allows you to access and control your holdings with just a few clicks."
                },
                {
                    title:"Insurance Coverage",
                    subtitle:"We provide insurance coverage for your assets to protect against unforeseen events, giving you an additional layer of security.",
                }
            ],
            products:[
                {
                    title:"Cold Storage",
                    subtitle:"Your assets are stored in offline wallets, eliminating the risk of online breaches."
                },
                {
                    title:"Multi-Signature Wallets",
                    subtitle:"Multiple layers of authorization ensure that your assets can only be accessed with your approval."
                },
                {
                    title:"Customized Reporting",
                    subtitle:"Stay informed about your holdings with detailed reports and analytics."
                },
            ]
        },
    }
}
};

export default custody
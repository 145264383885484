const form = {
    state(){
        return {
            icon:`<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-9 h-9 text-blue-700 mr-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
          </svg>
          `,
            title:'Tennet Bug Bounty Submission Form',
            subtitle:'Thank you for your commitment to helping us keep our systems secure. If you have identified a potential security vulnerability, please use this form to report it to our security team. We appreciate your efforts in making our platform safer.',
        }
    }
}

export default form
<template>
  <nav class="lg:hidden">
    <div class="flex justify-center w-full py-2 bg-primary">
      <span class="text-center text-white font-light">
        {{ this.$store.state.header.information1 }}
      </span>
    </div>
    <div class="w-full flex justify-center bg-transparent bg-white xl:px-9">
      <div class="flex w-[1160px] justify-between items-center">
        <slot name="desktop"></slot>
      </div>
    </div>
  </nav>
  <nav class="hidden lg:flex flex-col">
    <vue-marquee-slider
      id="marquee-slider-text"
      :space="10"
      :speed="13000"
      :width="780"
      class="w-full bg-[#4577AD] py-2"
    >
      <span class="text-center text-white font-light w-fit">
        {{ this.$store.state.header.information2 }}
      </span>
      <span class="text-center text-white font-light w-fit">
        {{ this.$store.state.header.information1 }}
      </span>
      <!-- <span class="text-center text-white font-light w-fit">
        {{ this.$store.state.header.information3 }}
      </span> -->
    </vue-marquee-slider>
    <div class="w-full flex justify-center bg-transparent bg-white">
      <div class="w-full flex justify-between items-start px-4">
        <slot name="mobile"></slot>
      </div>
    </div>
  </nav>
</template>

<script>
import { VueMarqueeSlider } from "vue3-marquee-slider";
import "../../../node_modules/vue3-marquee-slider/dist/style.css";

export default {
  components: {
    VueMarqueeSlider,
  },
};
</script>

<style scoped>
</style>
const team = {
  state() {
    return {
      team: {
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-9 h-9 text-blue-700 mr-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
          </svg>
          `,
        title: "Growth is never by mere chance; it is the result of forces working together.",
        title2: "Meet Tennet Team",
        team: {
          bod: [{
              image: "team_eric_1x.webp",
              name: "Eric Wicaksono",
              role: "President Director",
            },
            {
              image: "team_dennis_1x.webp",
              name: "Dennis",
              role: "Director of Business & Marketing",
            },
            {
              image: "team_yugo_1x.webp",
              name: "Dharmayugo Hermansyah",
              role: "Director of Compliance",
            },
          ],
          all: [{
              image: "team_faishal_1x.webp",
              name: "Faishal Amrullah",
              level: 2,
              role: "IT Manager",
            },
            {
              image: "team_ibad_1x.webp",
              name: "Abdul Ibad",
              level: 2,
              role: "IT Infrastructur",
            },
            {
              image: "team_hadi_1x.webp",
              name: "Hadi Gunawan",
              level: 2,
              role: "IT Development",
            },
            {
              image: "team_elizabeth_1x.webp",
              name: "Elizabeth",
              level: 2,
              role: "Business Development",
            },
            {
              image: "team_nadya_1x.webp",
              name: "Nadya Azka",
              level: 2,
              role: "Social Media",
            },
            {
              image: "team_jessica_1x.webp",
              name: "Jessica Septiany",
              level: 2,
              role: "Social Media Staff",
            },
            {
              image: "team_aldi_1x.webp",
              name: "Aldika Ananta",
              level: 2,
              role: "Asset Officer",
            },
            {
              image: "team_nanan_1x.webp",
              name: "Nandira Nikita",
              level: 2,
              role: "Graphic Design",
            },
            {
              image: "team_ardit_1x.webp",
              name: "Nyoman Arditya",
              level: 2,
              role: "Business Development",
            },
            {
              image: "team_ghani_1x.webp",
              name: "Abdul Ghani",
              level: 2,
              role: "HRGA",
            },
            {
              image: "team_ioni_1x.webp",
              name: "Ioni Hashinah",
              level: 2,
              role: "Legal & Compliance",
            },
            {
              image: "team_nilam_1x.webp",
              name: "Nilam Sari Dewi",
              level: 2,
              role: "Finance & Accounting",
            },
            {
              image: "team_yoshi_1x.webp",
              name: "Emilliano Jossie",
              level: 2,
              role: "IT Security",
            },
            {
              image: "team_iko_1x.webp",
              name: "Anrico Fadli",
              level: 2,
              role: "Front-End Developer",
            },

          ]
        },
      },
      division: [{
          title: "Board of Director",
          people: [{
              image: "team-edi.png",
              name: "Ir. Sutriono Edi MBA",
              role: "President Commissioner",
            },
            {
              image: "team-edric.png",
              name: "Edric Ng",
              role: "Commissioner",
            },
          ]
        },
        {
          title: "Business Development",
          people: [
            {
              image: "team-ade.png",
              name: "Ade Rizki Saputra",
              level: 2,
              role: "Director of Technology",
            },
            {
              image: "team-affan.png",
              name: "Muhamad Gafani",
              level: 2,
              role: "President Director",
            },
            {
              image: "team-dennis.png",
              name: "Dennis",
              level: 2,
              role: "Director of Business & Marketing",
            },
          ]
        },
        // {
        //   title: "IT Developer",
        //   people: [{
        //       image: "team_faishal_1x.webp",
        //       name: "Faishal Amrullah",
        //       level: 2,
        //       role: "IT Manager",
        //     },
        //     {
        //       image: "team_ibad_1x.webp",
        //       name: "Abdul Ibad",
        //       level: 2,
        //       role: "IT Infrastructur",
        //     },
        //     {
        //       image: "team_hadi_1x.webp",
        //       name: "Hadi Gunawan",
        //       level: 2,
        //       role: "IT Development",
        //     },
        //     {
        //       image: "team_yoshi_1x.webp",
        //       name: "Emilliano Jossie",
        //       level: 2,
        //       role: "IT Security",
        //     },
        //     {
        //       image: "team_iko_1x.webp",
        //       name: "Anrico Fadli",
        //       level: 2,
        //       role: "Front-End Developer",
        //     },
        //   ]
        // },
        // {
        //   title: "Creative & Design",
        //   people: [{
        //       image: "team_nadya_1x.webp",
        //       name: "Nadya Azka",
        //       level: 2,
        //       role: "Social Media",
        //     },
        //     {
        //       image: "team_jessica_1x.webp",
        //       name: "Jessica Septiany",
        //       level: 2,
        //       role: "Social Media Staff",
        //     },
        //     {
        //       image: "team_nanan_1x.webp",
        //       name: "Nandira Nikita",
        //       level: 2,
        //       role: "Graphic Design",
        //     },
        //   ]
        // },
        // {
        //   title: "HR & Legal",
        //   people: [{
        //       image: "team_ghani_1x.webp",
        //       name: "Abdul Ghani",
        //       level: 2,
        //       role: "HRGA",
        //     },
        //     {
        //       image: "team_ioni_1x.webp",
        //       name: "Ioni Hashinah",
        //       level: 2,
        //       role: "Legal & Compliance",
        //     },
        //     {
        //       image: "team_nilam_1x.webp",
        //       name: "Nilam Sari Dewi",
        //       level: 2,
        //       role: "Finance & Accounting",
        //     },
        //   ]
        // }
      ]
    };
  },
};

export default team;
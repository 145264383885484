const staking = {
  state() {
    return {
      hero: {
        img: "staking/staking-1.png",
        title:
          "Discover Institutional Staking Build Right Into Your Decentralized Custody Journey",
        subtitle:
          "Transform Your Asset Custody With Tennet And Put Your Treasury Under Secure Staking",
      },
      pair1: {
        img: "staking/staking-2.png",
        title:
          "Broaden The Scope Of Secure Custody With A Chain Agnostic Approach Of Staking Your Secure Assets",
        subtitle:
          "Earn A Handsome APR While Strengthening The Network And Being Compliant",
      },
      pair2: {
        img: "staking/staking-3.png",
        title:
          "Biggest TVL Chains Highest Marketcap Protocols Greatest Yield Rates",
        // subtitle:
        //   "Earn A Handsome APR While Strengthening The Network And Being Compliant",
        assets: [
          {
            icon: "asset-1.png",
            title: "MATIC",
            subtitle: "APR Upto 5.12%",
          },
          {
            icon: "asset-2.png",
            title: "ATOM",
            subtitle: "APR Upto 21.53%",
          },
          {
            icon: "asset-3.png",
            title: "ADA",
            subtitle: "APR Upto 3.31%",
          },
          {
            icon: "asset-4.png",
            title: "SOL",
            subtitle: "APR Upto 7.15%",
          },
        ],
      },
      pair3:{
        img:"staking/staking-4.png",
        title:"Expand On The Asset Security Aggregation By Staking With Tennet",
        subtitle:"Earning Passively Is Safer Now With A Blanket Of Security Applications For Web3 Protocols Exclusively",
        staking: [
            "Access Staking Integration Through A Cold Wallet",
            "Maximise Private Key Security With Multi-Sig and MPC Enabled Staking Mechanism",
            "Draft Custom Policies and Grant Permission To Manage Staking Wallet",
            "Drive Staking Reports Directly From The Dashboard",
            "Stabilize Price Action By Staking Maximum Liquidity"
        ],
      },
      pair4:{
        title:"Partnered With The Leaders In Procuring Most Sustainable Yields",
        images:[
            "staking-5.png",
            "staking-6.png",
        ],
        subtitle:"Revamping Institutionalized Staking To Empower Not Just Earnings But Asset Security And Management As Well",
        btn:{
            title:"Start Staking",
            url:"#"
        }
      }
    };
  },
};

export default staking;

const sdk = {
    state(){
        return {
            hero:{
                img:'sdk/sdk-1.png',
                title:'Discover Institutional Staking Build Right Into Your Decentralized Custody Journey',
                subtitle:'Transform Your Asset Custody With Tennet And Put Your Treasury Under Secure Staking'
            },
            benefit:{
                title:"What are the benefits?",
                enterprise:[
                    {
                        image: 'enterprise-1.png',
                        title: 'Tennet Wallet SDK',
                        subtitle: 'Integrates our digital asset wallets seamlessly with your application. Using the robust, unified interface, you can interact, create, sign, and manage transactions involving multiple digital assets from one place. Available in JavaScript and TypeScript',
                    },
                    {
                        image: 'enterprise-2.png',
                        title: 'Tennet API Suite',
                        subtitle: 'Using something other than JavaScript in your application? Our REST API suite will provide the necessary tools and resources to connect with Tennet solutions.',
                    },
                    {
                        image: 'enterprise-3.png',
                        title: 'Test Till You Are Satisfied',
                        subtitle: 'Unsure about the deployment? Don’t worry. We have you covered. Use our Test Environment connected to testnets of all supported protocols before switching over to the Production Environment',
                    },
                    {
                        image: 'enterprise-4.png',
                        title: 'Your Connection is Secure',
                        subtitle: 'There is no such thing as too much security. We take the need for security seriously, that’s why all requests from Tennet APIs and SDKs are channelled only through TLS and HTTPS connection protocols.',
                    },
                ]
            },
            best:{
                title:"Get the best out of our solutions"
            },
            integration:{
                img:'sdk/sdk-2.png',
                title:"Need for Tennet Integration",
                subtitle:"From hacking attacks to key mismanagement, crypto assets are always under threat. Using the right combination of wallets with significant automation and incorporation of best practices helps minimize these threats. Tennet’s solutions are designed keeping these in mind. Enterprises can adopt Tennet’s end-to-end wallet infrastructure solution or augment their existing setup with our products.",
                subtitles:[
                    'Enhance the security of your existing infrastructure with Tennet’s solutions',
                    'Automate your wallet processes while incorporating best industry practices',
                    'Minimize your overall operation costs',
                ]
            },
            api:{
                img:'sdk/sdk-3.png',
                title:'Our SDK and APIs',
                subtitle:'Tennet Software Development Kit (SDK) and APIs provides the necessary tools to connect Tennet wallet solution with your application. With Tennet SDK, you can list all wallets with their balances and transaction records, generate hot wallet addresses, access public keys of addresses, access HSM devices, create and sign transactions, monitor transaction status and more.',
                subtitles:[
                    'Seamless integration with Tennet wallet infrastructure solution',
                    'Tennet SDK for JavaScript and TypeScript based applications',
                    'REST API for integrating Tennet services with apps built using other languages'
                ]
            },
            test:{
                img:'sdk/sdk-4.png',
                title:'Test and Production Environments',
                subtitle:'In the Tennet ecosystem, we have both test and production environments in place to help developers test their integrations on testnet before connecting their applications to the mainnet. Both environments remain isolated from each other with no overlap of data or accounts.',
                subtitles:[
                    'Default access to test environment for everyone requesting access to dev tools',
                    'Connect to testnets of all supported protocols for simulated integration and performance testing of your apps following integration',
                    'Switch endpoints to enter production environment, connected to the mainnet of all supported digital assets'   
                ]
            },
            supported:{
                img:'sdk/sdk-5.png',
                title:'Supported Assets',
                subtitle:'At present Tennet supports 16 different blockchain protocols, with a lot more to be added soon. We will continue to expand the list of supported protocols as and when they become readily compatible with multisignature transactions and hardware security module signing.'
            },
            premium:{
                img:'sdk/sdk-6.png',
                title:'Premium Support',
                subtitle:'Our team is always ready to help. If you need more information about the Tennet DeFi solution or assistance with deployment, reach out to us.',
                btn:{
                    title:'Contact Us',
                    url:'#'
                }
            },
            devs:{
                img:'sdk/developer.svg',
                title: "For Developers",
                subtitle:"We have simple and robust client SDKs and other assets in place to help you integrate our digital asset wallets with your application. Check out the Tennet SDK and APIs, read out extensive documentation for more information. Contact us if you need more help.",
                btn:{
                    title:'Explore API',
                    url:"#"
                }
            }

        }
    }
}

export default sdk;
const faq = {
    state(){
        return {
            faq:{
                icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-9 h-9 text-blue-700 mr-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
              </svg>
              `,
                title:`Frequently Asked Question`,
                subtitle:`Welcome to our Frequently Asked Questions (FAQ) section! Here, we aim to provide you with clear and concise answers to common questions about our products, services, and policies. Whether you're a new customer seeking information or a long-time supporter looking for clarification, you'll find valuable insights here. If you can't find the answer you're looking for, please don't hesitate to reach out to our customer support team for further assistance. Let's dive in and explore the answers to your most pressing inquiries.`,
                faqs:[
                    {
                        icon:``,
                        title:"General",
                        faqs:[
                            {
                                ask:"What is Tennet?",
                                ans:"Established in 2021, Tennet offers clients access to a secure, institutional-grade offline storage solution that has been used by Indonesian Crypto exchange business. Tennet is an independent, BAPPEBTI-regulated entity built on Tennet’s crypto-first DNA, offering the most sophisticated and reliable custody solution in the world.",
                            },
                            {
                                ask:"Is Tennet Trust a regulated entity?",
                                ans:"Yes. We are regulated by the Badan Pengawas Perdagangan Berjangka Komoditi (BAPPEBTI), and operate as an independently capitalized entity, PT Tennet Depository Indonesia.",
                            },
                            {
                                ask:"How is Tennet different from a traditional custodian?",
                                ans:"While we offer a custody solution designed from the ground up to resolve unique challenges of storing crypto assets, we operate very similarly to traditional custodial services. We are overseen by the same regulators, held to the same capital requirements, and audited in the same way as a traditional financial custodian.",
                            },
                            {
                                ask:"What crypto assets can I store with you?",
                                ans:"We offer storage for an array of assets and are always adding more. See our asset page for an up-to-date list of the assets you can store with Tennet.",
                            },
                        ]
                    },
                    {
                        icon:``,
                        title:"Legal",
                        faqs:[
                            // {
                            //     ask:"Is Tennet Trust a qualified custodian?",
                            //     ans:"Yes, Tennet is a fiduciary under Rp 50 Bio of the Indonesia Banking Law and is licensed to custody its clients’ digital assets in trust on their behalf.",
                            // },                        
                            {
                                ask:"What is the global reputation of Tennet regulator?",
                                ans:"We work with our regulators (BAPPEBTI) on a regular cadence to ensure that Tennet operations are legal and compliant. BAPPEBTI is often considered a pioneer in its regulation of Indonesia's financial sector. BAPPEBTI is one of the first regulators to regulate crypto space with the 2019 launch of the Bappebti License. Many other jurisdictions have since followed its lead. Tennet treats all customer complaints and disputes seriously and views these as an opportunity to better serve our customers and improve our services. If you require any assistance or have any concerns, please contact your relationship manager or client services representative.",
                            }
    
                        ]
                    },
                    // {
                    //     icon:``,
                    //     title:"Insurance",
                    //     faqs:[
                    //         {
                    //             ask:"When was your insurance policy established?",
                    //             ans:"Protection measures for everyone Tennet has held coverage for all users continually since 2021.",
                    //         },                        
                    //         {
                    //             ask:"Who is providing your insurance?",
                    //             ans:"Tennet insurance policy is provided by a global syndicate of A XV/A+ rated insurers. It is coordinated by our broker of record, Insurance Risk Solutions.",
                    //         },
                    //         {
                    //             ask:"What storage systems does your insurance cover?",
                    //             ans:"Tennet policy covers cold storage methods. Read more about our insurance policy here or ask the Custody team for more information.",
                    //         },
                    //         {
                    //             ask:"What percentage of assets intended to be covered by your policy are covered?",
                    //             ans:"We carry an annually renewed commercial crime policy that carries a Rp.100bio limit (per-incident and overall), with Tennet as the named insured.",
                    //         },
    
                    //     ]
                    // },
                    {
                        icon:``,
                        title:"Security",
                        faqs:[
                            {
                                ask:"Has PT Tennet Depository Indonesia or Tennet ever lost client funds?",
                                ans:"No, Tennet never lost client funds since its establishment",
                            },                        
                            {
                                ask:"How are funds stored?",
                                ans:"Funds are stored in an offline, cold storage. Tennet has designed a proprietary, best-in-class key generation protocol to generate cold storage addresses for custody wallets.",
                            },
                            {
                                ask:"How do you help clients have good security hygiene?",
                                ans:"The security of our clients’ assets is our number one priority. We offer an array of account security configurations to suit your needs while ensuring the security of your account. Some features you can set up, address whitelisting, duress protocol setup, and many more.",
                            },
                        ]
                    },
                    // {
                    //     icon:``,
                    //     title:"Business Solutions",
                    //     faqs:[
                    //         {
                    //             ask:"How to access funds in absence of Tennet?",
                    //             ans:"All funds can be retrieved outside of Tennet by using your wallet key card.",
                    //         },                        
                    //         {
                    //             ask:"What measures have been implemented by Tennet to protect client data from internal teams?",
                    //             ans:"The best part about Tennet is that we never get access to your private keys, and no one from Tennet can access your funds as we deploy every possible way of information encryption on our devices and our employees.",
                    //         },
                    //         {
                    //             ask:"What if I lose my private key?",
                    //             ans:"Import your private key into your new device by using the seed phrase. We will help you at every step.",
                    //         },
                    //     ]
                    // },
                    // {
                    //     icon:``,
                    //     title:"Self Custody",
                    //     faqs:[
                    //         {
                    //             ask:"How does a self-custody wallet work?",
                    //             ans:"Self-custody wallet essentially holds private keys which help represent ownership of the digital assets. Plus, the self-custody cryptocurrency wallet is entirely held by the respective owner. With the self-custody wallet, the private keys are employed to sign transactions, whereas public keys help send funds to the appropriate address.",
                    //         },                        
                    //         {
                    //             ask:"What are the pros and cons of a custodial account?",
                    //             ans:"With a custodial account, the biggest downside is that users and investors are required to trust their funds and private keys to a third party. While setting up the custodial account, the user is required to go through login procedures and agree to all the terms and conditions. With custodial wallets, you don’t have to always manage your private keys while executing transaction.",
                    //         },
                    //         {
                    //             ask:"Is Ledger a self-custody wallet?",
                    //             ans:"Yes, absolutely. The ledger hardware wallet is a self-custody wallet. It allows you store private keys offline. Ledger offers a wide range of hardware wallets: Ledger Stax, Ledger Nano X, and Ledger Nano S Plus – to protect your digital assets and NFTs from sophisticated hacks.",
                    //         },
                            
                            
                    //         {
                    //             ask:"What is the difference between a blockchain and a self-custody wallet?",
                    //             ans:"A blockchain is a shared, immutable digital ledger stored across multiple nodes – that constitutes an encrypted record of transactions and asset ownership. On the other hand, a self-custody wallet allows users to maintain control over their wallet’s private keys. There are other kinds of custodial wallets that store private keys on their in-house servers allowing the user to access their funds instantly. Both wallets allow you to transact over blockchain without friction.",
                    //         },                        
                    //         {
                    //             ask:"Why is Self-custody important for crypto?",
                    //             ans:"A wallet is required to transact digital assets and tokens within a decentralised crypo-finance ecosystem. Custodial wallet manages your keys for you whereas self-custody cryptocurrency wallet let you manage your keys. Self-custody wallets are critical for the crypto industry because it enables crypto investors and users to maintain complete control over their crypto investments without the interference of third-party institutions. Additionally, self-custody wallet allows users to trade with cutting-edge DeFi protocols and a diverse range of Dapps.",
                    //         },
                    //         {
                    //             ask:"Can self-custody be changed?",
                    //             ans:"Yes, absolutely; you can switch your wallet from self-custodian format to custodian. If you find managing funds using a self-custody wallet difficult, then you can opt for a custodian wallet. With a custodian wallet, you can easily share your public address with other parties. But, the risk of losing all your crypto-asset is significantly higher with a custodian wallet. A bug in the custodian wallet application can lead to loss of life savings.",
                    //         },
                    //         {
                    //             ask:"What is self-custody staking?",
                    //             ans:"With self-custody staking, users get the opportunity to receive an incentive for staking their asset token. Investors and users can stake their tokens using a self-custody cryptocurrency wallet, enabling them to earn for their crypto-holdings. But, operating as a blockchain network’s node validator and staking token using a self-custody cryptocurrency wallet is a highly technical task and time-consuming.",
                    //         },
                    //     ]
                    // },
                    // {
                    //     icon:``,
                    //     title:"Smart Refill Wallet",
                    //     faqs:[
                    //         {
                    //             ask:"Can I add a certain threshold for wallet refill?",
                    //             ans:"The ability to add a certain threshold for wallet refill depends on the features and options the specific wallet provider provides. Some wallets may offer settings or preferences that allow users to set a predefined threshold for automatic wallet refills when the balance falls below a specified amount.",
                    //         },                        
                    //         {
                    //             ask:"Is it safe to use the Smart Refill Wallet for high-value transactions?",
                    //             ans:"The safety of using the Smart Refill Wallet for high-value transactions depends on the wallet provider’s specific security measures and reputation. It is recommended to ensure the wallet has robust security protocols such as encryption, multi-factor authentication, and adherence to industry best practices to mitigate risks associated with high-value transactions.",
                    //         },                        
                    //         {
                    //             ask:"How can I add funds to my Smart Refill Wallet?",
                    //             ans:"To add funds to your Smart Refill Wallet, you can typically follow these steps: Access the Smart Refill Wallet platform or app. Navigate to the “Add Funds” or “Deposit” section. Choose the desired funding method, such as bank transfer, credit/debit card, or cryptocurrency. Follow the instructions provided to complete the deposit process and add funds to your Smart Refill Wallet.",
                    //         },                        
                    //         {
                    //             ask:"What happens if my Smart Refill Wallet transaction fails?",
                    //             ans:"Failed transactions don’t occur that frequently with a smart refill wallet. But it is important to note that each transaction that fails will cost an amount based on the prices and gas fees. So, if one of your transactions fails, adjust slippage or gas, and try again.",
                    //         },                        
                    //         {
                    //             ask:"Can I use the Smart Refill Wallet to refill any wallet?",
                    //             ans:"Yes, you can use a smart refill wallet to refill your wallet in accordance with the predefined rules automatically. It usually starts filling your wallet once the declared parameters are met.",
                    //         },                        
                            
                    //     ]
                    // },
                    // {
                    //     icon:``,
                    //     title:"Staking",
                    //     faqs:[
                    //         {
                    //             ask:"What is staking in crypto?",
                    //             ans:"Staking in web3 refers to holding and periodically locking a certain amount of cryptocurrency in a staking wallet or platform. It allows stakers to support the blockchain’s operations, such as network security and consensus mechanism. By engaging in staking, you can leverage your digital assets. Staking allows you to generate passive income, all while retaining ownership of your digital asset.",
                    //         },                        
                    //         {
                    //             ask:"How does cryptocurrency staking work?",
                    //             ans:"Staking involves holding a certain amount of a specific cryptocurrency in a staking wallet or platform. By doing so, you contribute to the network’s security and consensus mechanism. In return for your contribution, you receive staking rewards, typically in the form of additional cryptocurrency, as an incentive for participating in maintaining the network’s integrity.",
                    //         },                        
                    //         {
                    //             ask:"Which cryptocurrency is best for staking?",
                    //             ans:"Choosing the best cryptocurrency for staking depends on individual preferences and factors such as projected returns, project credibility, and personal investment strategy. Popular cryptocurrencies for staking include Ethereum (ETH), Cardano (ADA), Polkadot (DOT), and Chainlink (LINK), among others. It’s recommended to research and assess each project’s staking parameters and potential rewards before deciding.",
                    //         },                        
                    //         {
                    //             ask:"What is staking vs mining?",
                    //             ans:"Staking and mining are two different methods used in blockchain networks. Staking involves holding and “staking” a cryptocurrency to support the network’s operations and validate transactions. It allows stakers to earn rewards in return. Conversely, mining involves using computational power to solve complex mathematical problems and create new blocks. It allows miners to earn rewards through the mining process.",
                    //         },                        
                    //         {
                    //             ask:"What type of returns are expected out of Satking?",
                    //             ans:"Factors such as the specific cryptocurrency being staked, the staking platform’s rule, the network’s staking rewards model, and the staker’s stake amount and duration affect the expected returns. Note: Staking returns are subject to market conditions and fluctuate over time. For example: Coinbase offers 4.00% APY for staking your Ethereum.",
                    //         },                        
                    //         {
                    //             ask:"Do we need our own validator node?",
                    //             ans:"No, you don’t need validator nodes. We can set up our own validator node in association with Figment for you.",
                    //         },                        
                    //         {
                    //             ask:"When can we unlock/redeem rewards?",
                    //             ans:"The rewards will be unlocked when the locking period ends. Locking period varies from protocol to protocol. For example, for $ATOM staking the lock-in period is 21 days (about three weeks).",
                    //         },                        
                            
                    //     ]
                    // },
                ]
            },
        }
    }
};

export default faq;
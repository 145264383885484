const custody = {
state(){
    return {
        settlement: {
            icon:`<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-9 h-9 text-blue-500 mr-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
          </svg>
          `,
            title: `Crypto Settlement`,
            subtitle:`Professional clearing, execution and trade settlement for digital assets, crypto and global currencies`,
            settlement:{
                icon:``,
                subtitle:[
                    `Tennet provides support for managing a range of global currencies, cryptocurrencies and digital assets, providing end-to-end governance via a solutions suite that supports automation at scale. From custody to market access, our multi-asset management solutions enhance transactional efficiency.`,
                    `Tennet Custody offers digital asset risk management solutions that go beyond mere risk avoidance. Tennet helps you build and sustain a culture of compliance as well as a reputation for having your people and operations perform at their best. You can turn regulatory complexity into business opportunity even as regulations grow in number and complexity.`
                ]
            },
            list:[
                {
                    title:"Rapid Settlement",
                    subtitle:"We understand the importance of swift transactions in the crypto space. With our advanced technology and expertise, we ensure that your settlements are executed promptly, reducing exposure to market volatility"
                },
                {
                    title:"Comprehensive Coverage",
                    subtitle:"Our settlement services cover a wide range of cryptocurrencies, enabling you to transact with various digital assets while enjoying a unified and hassle-free experience."
                },
                {
                    title:"Transparent Pricing",
                    subtitle:"We offer competitive and transparent pricing, so you know exactly what to expect. Say goodbye to hidden fees and surprises in your settlement process."
                },
                {
                    title:"Risk Mitigation",
                    subtitle:"Tennet employs robust risk management strategies to safeguard your assets during the settlement process, ensuring that your transactions are secure and reliable."
                },
                {
                    title:"Dedicated Support",
                    subtitle:"Our team of experienced professionals is available 24/7 to provide support and assistance, ensuring that your settlement needs are met at any time."
                }
            ],
            products:[
                {
                    title:"Real-time Settlement",
                    subtitle:"Experience the convenience of real-time settlement, allowing you to access your funds when you need them most."
                },
                {
                    title:"Multi-Currency Support",
                    subtitle:"Our platform supports a wide array of cryptocurrencies, making it easy to settle transactions in the digital assets of your choice."
                },
                {
                    title:"Regulatory Compliance",
                    subtitle:"Tennet adheres to all relevant regulations and compliance standards, providing you with peace of mind and assurance in your crypto settlements."
                }

            ]
        },
    }
}
};

export default custody
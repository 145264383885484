const academy = {
    state() {
        return {
            title: "Tennet Academy",
            heroText: "Unlock the Future Finance at Tennet Academy",
            subText: "Dive into the world of Bitcoin, Crypto, and Ethereum with our cutting-edge web academy.",
            heroImg: "heroacademy_4x.webp",
            contents:[
                {
                    thumbnail:'edu1.webp',
                    title:'Crypto Custody Solutions: Need of the Hour as Crypto Adoption Gains Traction',
                    meta:'With the mass adoption of crypto assets underway, institutional investors, enterprises, and high net-worth',
                    category:"Bitcoins"
                },
                {
                    thumbnail:'edu2.webp',
                    title:'Crypto Security: Simplifying complexities for an Individual',
                    meta:'With the growing adoption of cryptocurrency, it is becoming more and ',
                    category:"Crypto"
                },
                {
                    thumbnail:'edu3.webp',
                    title:'Difference between Crypto Wallet and Exchange',
                    meta:'Sometimes it gets harder for new users to understand the fundamental difference between an exchange and a wallet',
                    category:"Ethereum"
                },
                {
                    thumbnail:'edu4.webp',
                    title:'What is blockchain infrastructure?',
                    meta:'Every single system and process relies on suitable infrastructure for its proper functioning. In the realm of technology, infrastructure plays ',
                    category:"Altcoin"
                },
                {
                    thumbnail:'edu5.webp',
                    title:'What are ERC-20 tokens?',
                    meta:'Ether, also called ETH, represents an entire ecosystem that empowers individuals to unleash their creativity and build a wide',
                    category:"NFT"
                },
                {
                    thumbnail:'edu6.webp',
                    title:'What is Proof Of Reserve?',
                    meta:'Following the FTX collapse, proof of reserves has become a prominent topic',
                    category:"NFT"
                },
                {
                    thumbnail:'edu7.webp',
                    title:'What is mpc wallet?',
                    meta:'Both MPC crypto wallet and multi-sig wallet are designed to ',
                    category:"Metaverse"
                },
                {
                    thumbnail:'edu8.webp',
                    title:'What are BEP2 tokens?',
                    meta:'Navigating the Binance (BNB) ecosystem can be difficult. Whether you aim to trade, transact, or establish digital',
                    category:"DeFi"
                },
                {
                    thumbnail:'edu9.webp',
                    title:'What is a multisig wallet?',
                    meta:'You bought your favorite crypto-currency, but where should you store it?',
                    category:"Bitcoins"
                },
            ]
        }
    }
}

export default academy
const supported = {
    state(){
        return {
            supported:{
                icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-9 h-9 text-blue-700 mr-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21 7.5l-2.25-1.313M21 7.5v2.25m0-2.25l-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3l2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75l2.25-1.313M12 21.75V19.5m0 2.25l-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25" />
              </svg>
              `,
                title:"Supporting Leading Protocols",
                subtitle:"Tennet provides unmatched access to the world’s top protocols and tokens. Build on all major protocols, such as Bitcoin, Ethereum, Solana, Cardano, Tron, and many others.",
                live:[
                    {

                        image:"live-1.png",
                        title:"Bitcoin"
                    },
                    {

                        image:"live-2.png",
                        title:"Ethereum"
                    },
                    {

                        image:"live-3.png",
                        title:"Polygon"
                    },
                    {

                        image:"live-4.png",
                        title:"Avalache"
                    },
                    {

                        image:"live-5.png",
                        title:"Solana"
                    },
                    {

                        image:"live-6.png",
                        title:"Cosmos"
                    },
                   
                    {

                        image:"live-7.png",
                        title:"Litecoin"
                    },
                    {

                        image:"live-8.png",
                        title:"Arbitrium"
                    },
                    {

                        image:"live-9.png",
                        title:"Bitcoin Cash"
                    },
                    {

                        image:"live-10.png",
                        title:"Tron"
                    },
                    {

                        image:"live-11.png",
                        title:"Dogecoin"
                    },
                    {

                        image:"live-12.png",
                        title:"Optimism"
                    },
                    {

                        image:"live-13.png",
                        title:"Xinfin"
                    },
                    {

                        image:"live-14.png",
                        title:"Ripple"
                    },
                    {

                        image:"live-15.png",
                        title:"Cardano"
                    },
                    {

                        image:"live-16.png",
                        title:"BNB Chain"
                    },
                    {

                        image:"live-17.png",
                        title:"Tezos"
                    },
                    {

                        image:"live-18.png",
                        title:"Klaytn"
                    },
                    {

                        image:"live-19.png",
                        title:"Stellar"
                    },
                    {

                        image:"live-20.png",
                        title:"Polkadot"
                    },
                    {

                        image:"live-21.png",
                        title:"Near"
                    },
                ],
                pipeline:[
                    {
    
                        image:"pipeline-1.png",
                        title:"Hedera"
                    },
                    
                    {
    
                        image:"pipeline-2.png",
                        title:"EOS"
                    },
                    
                    {
    
                        image:"pipeline-3.png",
                        title:"Fantom"
                    },
                    
                    {
    
                        image:"pipeline-4.png",
                        title:"Moonbeam"
                    },
                    
                    {
    
                        image:"pipeline-5.png",
                        title:"Harmony"
                    },
                    
                    ]
            },
            
        }
    }
}
export default supported 
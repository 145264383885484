<template>
  <div class="">
    <div
      :class="[!view.atTopOfPage ? 'shadow-sm' : '']"
      class="sticky z-50 top-0 bg-[#EEF7FE]"
    >
      <HeaderComp />
    </div>
    <RouterView />
    <ModalApp />
  </div>
</template>

<script>
import HeaderComp from "./components/HeaderComp.vue";
import ModalApp from "./components/dasboard/ModalApp.vue";
import store from "./store";
import AOS from "aos";

export default {
  store,
  name: "App",
  beforeMount() {
    AOS.init();
  },
  data() {
    return {
      view: {
        atTopOfPage: true,
      },
    };
  },

  // a beforeMount call to add a listener to the window
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    // the function to call when the user scrolls, added as a method
    handleScroll() {
      // when the user scrolls, check the pageYOffset
      if (window.pageYOffset > 0) {
        // user is scrolled
        if (this.view.atTopOfPage) this.view.atTopOfPage = false;
      } else {
        // user is at top of page
        if (!this.view.atTopOfPage) this.view.atTopOfPage = true;
      }
    },
  },
  components: {
    ModalApp,
    HeaderComp,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

html {
  scroll-behavior: smooth;
}

* {
  font-family: lato;
}

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

.app {
  width: 100vw;
  height: 500vh;
  background: hsl(200, 50%, 90%);
}

.navbar {
  height: 60px;
  width: 100vw;
  background: hsl(200, 50%, 50%);
  position: fixed;
  box-shadow: 0 2px 15px rgba(71, 120, 120, 0.5);
  transform: translate3d(0, 0, 0);
  transition: 0.1s all ease-out;
}

.navbar.navbar--hidden {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);
}
</style>

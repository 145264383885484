import { createStore } from "vuex";

import sidebar from "./store/sidebar";
import header from "./store/header";
import footer from "./store/footer";
import assets from "./store/assets";

import homepage from "./store/homepage";

import custody from "./store/custody";
import settlement from "@/store/settlement"
import supported from "./store/pages/dev/supported.js"

import faq from "./store/faq";
import education from "./store/education";
import academy from "./store/academy"

import contactUs from "./store/contact_us";
import career from "./store/company/career";
import team from "./store/team";
import aboutUs from "./store/company/about_us";


import solution from "./store/product/solution";
import sdk from "./store/product/sdk";
import self from "./store/product/self";
import refill from "./store/product/refill";
import staking from "./store/product/staking";

import bounty from "./store/pages/dev/bounty";
import form from "./store/pages/dev/form";

const store = createStore({
  modules:{
    contactUs, education, 
    homepage, footer, header, assets, 
    custody, settlement, faq, sidebar,
    team, supported, career, solution,
    sdk, self, refill, staking, aboutUs,
    bounty, form, academy
  },
  state() {
    return {
    lottie_size: 400,
    vector_size: 400,
    };
  },
  mutations: {
    toRegister(){
      window.open('https://auth.tennet.id/register', "__blank");
    },
    openLink(_, p) {
      window.open(p, "__blank");
    },
  },
});

export default store;

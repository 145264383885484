const about_us = {
  state() {
    return {
      hero: {
        img: "aboutus/aboutus-1.png",
        imgTeam:"team-1.svg",
        title: "On A Journey To Transform Digital Asset Management",
        subtitle:
          "Embark on a journey to revolutionize your digital asset management practices with Tennet.",
      },
      number: {
        title: "Numbers That Speak Volume",
        list: [
          {
            title: "$550Mn+",
            subtitle: "Assets under protection",
          },
          {
            title: "$6,000Mn+",
            subtitle: "Transactions processed",
          },
          {
            title: "1,000+",
            subtitle: "Manual hours saved",
          },
          {
            title: "$600Mn+",
            subtitle: "Wallet refills processed",
          },
        ],
      },
      principle: {
        title: "Hard-Coded With Principles of 4Ss",
        subtitle:
          "Tennet is built on certain core principals and values. Everything we do at Tennet is driven by these values.",
        list: [
          {
            img: "principal-1.png",
            title: "Security",
            subtitle:
              "We adopted a security-first approach across over wallet and custody solutions.",
          },
          {
            img: "principal-2.png",
            title: "Sovereignty",
            subtitle:
              "We strongly believe that self-custody should be practised by all institutions",
          },
          {
            img: "principal-3.png",
            title: "Support",
            subtitle:
              "We offer a bespoke service and onboarding experience with expert-led concierge establishment",
          },
          {
            img: "principal-4.png",
            title: "Scalability",
            subtitle:
              "We provide innovation-oriented solutions that help add scalability for protocols explicitly",
          },
        ],
      },
      pair1: {
        img: "aboutus/aboutus-2.png",
        title: "Idea Behind Tennet",
        subtitle:
          "Tennet is a digital asset wallet and custody infrastructure, custom developed to suit Web3 Institutions’ asset management requisites. Our team of working extensively in the crypto & security ecosystem had witnessed all kinds of vulnerabilities and inefficiencies in managing digital assets. This led to the formation of Tennet with a clear goal – to provide a secure, automated, efficient, cost-effective, and user-friendly framework maximum level of self-custody.",
      },
      pair2: {
        reverse: 0,
        img: "aboutus/aboutus-3.png",
        title: "Tennet's Positioning",
        subtitle:
          "Tennet is a Indonesia-based wallet and custody infrastructure provider with a secure architecture to supplement crypto-native businesses’ need for secure asset management. Tennet has attained significant security and compliance certifications like ISO 27001 standards & SOC Type II. Alternatively, Tennet is also bringing innovation into wallet technologies along with enterprise-grade policies and automation capabilities, encapsulating a multi-chain and multi-asset support system and integration to create an impact on how Institutions safeguard digital assets inevitably.",
      },
      // pair3: {
      //   reverse: 0,
      //   img: "aboutus/aboutus-4.png",
      //   title: "Tennet's Proposition",
      //   subtitle:
      //     "Tennet stands firm on the first principle of “Not Your Keys, Not Your Coins”, driving all its solutions to include multi-dimensional self-custody parameters. Through our operational framework, Tennet is also able to enhance hot and cold wallet encryptions, accelerate algorithm-based transaction confirmation and gas fee-saving mechanisms and initiate a dedicated onboarding experience. Tennet facilitates Wallet Engineers by saving wallet deployment cost by upto 30%, aids Finance teams in saving gas fees by upto 15% and improvises CTOs’ operational efficiency by upto 90%.",
      // },
      pair4: {
        reverse: 1,
        img: "aboutus/aboutus-5.png",
        title: "A Blanket Of Compliance and Regulation Checks",
        subtitle: [
          "Transaction Monitoring",
          "KYB",
          "Policy Engine",
          "Address Whitelisting",
        ],
      },
      pair5: {
        reverse: 0,
        img: "aboutus/aboutus-7.png",
        title: "Ushering Into A Smart Custody Era",
        subtitle: [
          "Intuitive Interface for Builders and Signers alike",
          "Step-by-Step guide to run multi-wallet secured setup",
          // "Mobile First approach to give mobility to transaction processing",
          // "Grouping of wallets to prevent a single point of failure",
          // "Continued access to assets with no intermediaries",
        ],
      },
      // team: {
      //   img: "aboutus/aboutus-6.png",
      //   title: "Meet Our Team",
      //   btn: {
      //     title: "Meet the Team",
      //     url: "",
      //   },
      // },
    };
  },
};

export default about_us;

<template>
    <div :class="[opt_css]" class="w-max bg-primary duration-300 hover:bg-[#355D86] rounded-md my-2">
        <button class=" text-white py-3 px-6 rounded-md">{{ title }}</button>
    </div>
</template>

<script>
export default {
    props:["title","state", "opt_css"]
}
</script>
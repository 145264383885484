const bounty = {
  state() {
    return {
      en: {
        hero: {
          icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-9 h-9 text-blue-700 mr-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 12.75c1.148 0 2.278.08 3.383.237 1.037.146 1.866.966 1.866 2.013 0 3.728-2.35 6.75-5.25 6.75S6.75 18.728 6.75 15c0-1.046.83-1.867 1.866-2.013A24.204 24.204 0 0112 12.75zm0 0c2.883 0 5.647.508 8.207 1.44a23.91 23.91 0 01-1.152 6.06M12 12.75c-2.883 0-5.647.508-8.208 1.44.125 2.104.52 4.136 1.153 6.06M12 12.75a2.25 2.25 0 002.248-2.354M12 12.75a2.25 2.25 0 01-2.248-2.354M12 8.25c.995 0 1.971-.08 2.922-.236.403-.066.74-.358.795-.762a3.778 3.778 0 00-.399-2.25M12 8.25c-.995 0-1.97-.08-2.922-.236-.402-.066-.74-.358-.795-.762a3.734 3.734 0 01.4-2.253M12 8.25a2.25 2.25 0 00-2.248 2.146M12 8.25a2.25 2.25 0 012.248 2.146M8.683 5a6.032 6.032 0 01-1.155-1.002c.07-.63.27-1.222.574-1.747m.581 2.749A3.75 3.75 0 0115.318 5m0 0c.427-.283.815-.62 1.155-.999a4.471 4.471 0 00-.575-1.752M4.921 6a24.048 24.048 0 00-.392 3.314c1.668.546 3.416.914 5.223 1.082M19.08 6c.205 1.08.337 2.187.392 3.314a23.882 23.882 0 01-5.223 1.082" />
                  </svg>
                  `,
          title: "Bug Bounty Program",
          subtitle: `At Tennet, we take security seriously. We believe that the collective power of the security community can help us make our systems and applications more robust. That's why we're excited to invite you to participate in our Bug Bounty Program.`,
        },
        opening: [
          "Tennet is one of the most trusted digital asset custody brands globally. Our platform is built on the industry’s best security measures, which are regularly tested and verified against violations.",
          "At Tennet, security is at the heart of everything we do. Hence, we want to remain steadfast to our core with the “Bug Bounty Program”. With this program, we would like to reward your support and efforts if you can help us identify loopholes and plug them immediately without any errors. We encourage responsible disclosure of security vulnerabilities using this program. Please follow the policies to report the bugs. Breaching any program policies may lead to legal consequences for the violator.",
        ],
        target: {
          icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-blue-700 mr-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                  </svg>
                  `,
          title: "Target Sites",
          subtitles: ["vault.tennet.id", "api.tennet.id", "keys.tennet.id"],
          btn: {
            title: "Submit a report",
            url: "#",
          },
        },
        scope: {
          icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-9 h-9 text-blue-700 mr-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                  </svg>
                  `,
          title: "Out of Scope",
          subtitles: [
            "Any targets besides the one mentioned in the target list.",
            "All third party applications used at Tennet",
            "The Tennet marketing website www.tennet.id",
          ],
        },
        rules: {
          icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-9 h-9 text-blue-700 mr-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
        </svg>
        `,
          title: "Rules",
          subtitles: [
            "Please use your own account for testing or research purposes. Do not attempt to gain access to another user’s account or confidential information.",
            "Submit one vulnerability per report, unless you need to chain vulnerabilities to provide impact.",
            "When duplicates occur, we only award the first report that was received (provided that it can be fully reproduced).",
            "Multiple vulnerabilities caused by one underlying issue will be awarded one bounty.",
            "Please do not test for spam, social engineering, or denial of service issues. Such activities against Tennet employees and end-user are prohibited.",
            "Your testing must not violate any law, or disrupt or compromise any data that is not your own.",
            "By responsibly submitting your findings to Tennet in accordance with these guidelines, Tennet agrees not to pursue legal action against you. Tennet reserves all legal rights in the event of non-compliance with these guidelines.",
            "Contact us immediately if you inadvertently encounter user or financial transactions data. Do not view, alter, save, store, transfer, or otherwise access the data, and immediately purge any local information upon reporting the vulnerability to Tennet.",
          ],
        },
        qualify: {
          icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-9 h-9 text-blue-700 mr-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
        </svg>
        `,
          title: "Qualifying Vulnerabilities",
          subtitle:
            "When reporting vulnerabilities, please consider 1 attack scenario/exploitability, and 2 the security impact of the bug. The following issues are considered in scope:",
          subtitles: [
            "Balance Manipulation",
            "User Account Take over",
            "Cross-site Scripting (XSS)",
            "Cross-Site Request Forgery (Only potential issues will be considered)",
            "Server-Side Request Forgery (SSRF)",
            "SQL Injection",
            "Server-Side Remote Code Execution (RCE)",
            "XML External Entity Attacks (XXE)",
            "Access Control Issues (Insecure Direct Object Reference Issues, Privilege Escalation, etc)",
            "Exposed Administrative Panels that don’t require login credentials",
            "Directory Traversal Issues",
            "Local File Disclosure (LFD) and Remote File Inclusion (RFI)",
            "Gaining access to any of our servers",
            "Leakage of PII Information of individual or other users.",
          ],
        },
        non_qualify: {
          icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-9 h-9 text-blue-700 mr-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3 3l1.664 1.664M21 21l-1.5-1.5m-5.485-1.242L12 17.25 4.5 21V8.742m.164-4.078a2.15 2.15 0 011.743-1.342 48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185V19.5M4.664 4.664L19.5 19.5" />
        </svg>
        `,
          title: "Non-Qualifying vulnerabilities",
          subtitles: [
            "Any URIs leaked because a malicious app has permission to view URIs opened",
            "Absence of code obfuscation",
            "Self XSS",
            "Login/logout cross-site request forgery",
            "Sensitive data in URLs/request bodies when protected by TLS.",
            "Use of outdated software/library versions.",
            "Path disclosure in the binary",
            "Snapshot/Pasteboard leakage",
            "Run-time hacking exploits (exploits only possible in a jail-broken/rooted environment)",
            "Reports from automated tools or scans (without accompanying demonstration of exploitability)",
            "Bypassing client-side control mechanisms through scanners or tools or debuggers are considered to be known vulnerabilities, post-bypass, if there is any impact on users, account then it will be reviewed by the Tennet product security team.",
            "Clickjacking and open-redirect are out of scope unless it has an impact on users’ data.",
            "Rate limiting on our services like resending verification emails, inviting members, subscribing to newsletters, or any others",
            "MFA before email verification allowed and MFA working after the password change",
            "Registering an account with any email available",
            "Password field accepting many characters",
            "DMARC related issues",
            "DNSSEC not set",
          ],
        },
        reward: {
          icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-9 h-9 text-blue-700 mr-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
        </svg>
        `,
          title: "Reward Guidelines",
          subtitle:
            "Every valid security bug qualifies for rewards based on the severity of the identified bug. The severity of the bug and the corresponding reward depends on the criticality of the issue and will be determined at the sole discretion of our product security team. All changes to the code and/or to the configuration ensures an entry to our Hall of Fame. All changes with higher severity levels get further rewarded with cash payouts (as per the below table) of up to $1000 depending on the severity of the bug as well as its immediate effect on the Tennet infrastructure.",
        },
        treshold: {
          icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-9 h-9 text-blue-700 mr-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
        </svg>
        `,
          title: "Reward Guidelines",
          level: {
            title: "Severity Level",
            subtitles: ["Low-Medium", "Medium", "Medium-High", "High"],
          },
          reward: {
            title: "Reward",
            subtitles: [
              "HoF to $50",
              "$50 to $100",
              "$100 to $250",
              "$250 to $500",
            ],
          },
        },
      },
    };
  },
};

export default bounty;

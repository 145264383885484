<template>
  <BaseHeader>
    <template v-slot:desktop>
      <nav class="flex justify-between items-center py-2" aria-label="navigation dekstop">
        <div
          @click="navigate('home')"
          v-html="this.$store.state.assets.logo"
        ></div>
        <!-- <div class="hidden lg:flex sm:flex md:flex xs:flex">
          <Bars2Icon
            v-if="!open"
            @click="open = !open"
            class="cursor-pointer"
          />
        </div> -->
      </nav>
      <div class="flex items-center lg:hidden">
        <div
          class="cursor-pointer px-4 py-2"
          v-for="(d, i) in this.$store.state.header.h_navbar"
          :key="i"
          @click="navigate(d.url)"
          @mouseenter="hover = d.title"
          @mouseleave="hover = null"
        >
          <div
            class="flex items-center space-x-2 duration-300 max-w-[200px] group"
          >
            <h3
              class="font-normal text-base group-hover:text-blue-500 text-black"
              :class="{ 'text-blue-500': hover === d.title }"
            >
              {{ d.title }}
            </h3>
            <svg
              v-if="d.childs"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4 hover:duration-500 rotate-180 fill-current group-hover:text-blue-500 text-black"
              :class="{ 'text-blue-500': hover === d.title }"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4.5 15.75l7.5-7.5 7.5 7.5"
              />
            </svg>
          </div>
          <Transition name="slide">
            <div
              class="bg-white absolute z-50 rounded-md border-t-2 border-sky-700 mt-2 shadow-sm p-4"
              v-if="hover == d.title && d.childs"
            >
              <div
                class="hover:bg-sky-100 px-4 py-2 w-full rounded-md text-slate-950"
                v-for="(d, i) in d.childs"
                :key="i"
                @click="navigate(d.url, d.external)"
              >
                <div class="flex justify-start">
                  <p class="text-slate-950">{{ d.title }}</p>
                  <svg
                    v-if="d.external"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-3 h-3 font-bold"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </Transition>
        </div>
        <AsyncButton2
          class="lg:self-start md:px-9 2xl:ml-11"
          :title="'Request Access'"
          @click="
            this.$store.commit('toRegister');
            open = false;
          "
        />
      </div>
    </template>

    <template v-slot:mobile>
      <nav
        :class="[open == true ? 'overflow-hidden' : '']"
        class="w-full flex justify-between items-center" aria-label="navigation mobile"
      >
        <div
          @click="navigate('home')"
          v-html="this.$store.state.assets.logo"
        ></div>
        <div class="hidden lg:flex">
          <Bars2Icon
            v-if="!open"
            @click="open = !open"
            class="w-8 h-8 text-black cursor-pointer"
          />
        </div>

        <div
          v-if="open"
          @click="open = !open"
          class="bg-slate-600/25 absolute w-full h-screen top-0 right-0 flex justify-end p-4"
        ></div>
        <Transition name="slide-fade">
          <nav
            v-if="open"
            class="z-10 flex flex-col w-2/3 h-screen absolute top-0 left-0 bottom-0 bg-white shadow-sm overflow-scroll"
          >
            <div class="flex justify-between p-4">
              <div
                @click="navigate('home')"
                v-html="this.$store.state.assets.logo"
              ></div>
              <XMarkIcon
                v-if="open"
                @click="open = !open"
                class="w-5 h-5 text-state-500 cursor-pointer bg-primary fill-current rounded-sm text-white"
              />
            </div>
            <div
              class="cursor-pointer font-bold text-slate-900 p-8 py-2"
              v-for="(d, i) in this.$store.state.header.h_navbar"
              :key="i"
              @click="navigate2(d)"
            >
              <div
                class="flex items-center space-x-2 duration-300 max-w-[200px]"
              >
                <h3 class="font-normal my-2">{{ d.title }}</h3>
                <svg
                  v-if="d.childs"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-2 h-2 hover:duration-500 rotate-180"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.5 15.75l7.5-7.5 7.5 7.5"
                  />
                </svg>
              </div>
              <Transition name="slide">
                <ul
                  class="bg-white text-slate-500 pl-4"
                  v-if="hover == d.title && d.childs"
                >
                  <li
                    class="font-normal my-2 flex"
                    v-for="(d, i) in d.childs"
                    :key="i"
                    @click="navigate(d.url, d.external)"
                  >
                    <p>{{ d.title }}</p>
                    <svg
                      v-if="d.external"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-3 h-3 font-bold"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                      />
                    </svg>
                  </li>
                </ul>
              </Transition>
            </div>
            <AsyncButton2
              :opt_css="'ml-8'"
              class="lg:self-start"
              :title="'Request Access'"
              @click="
                this.$store.commit('toRegister');
                open = false;
              "
            />
          </nav>
        </Transition>
      </nav>
    </template>
  </BaseHeader>
</template>

<script>
import { Bars2Icon, XMarkIcon } from "@heroicons/vue/24/solid";
import BaseHeader from "./base/BaseHeader.vue";
import AsyncButton2 from "./button/AsyncButton2.vue";
import { useRouter } from "vue-router";

export default {
  components: {
    BaseHeader,
    XMarkIcon,
    Bars2Icon,
    AsyncButton2,
  },
  data() {
    return {
      open: false,
      hover: null,
      router: useRouter(),
    };
  },
  methods: {
    navigate(url, ext) {
      if (ext) {
        window.open(ext);
        return;
      }
      if (url == "#") {
        return;
      } else if (url == this.$route.name) {
        this.hover = null;
        this.open = false;
      }
      this.router.push({ name: url });
      this.hover = null;
      this.open = false;
    },
    navigate2(d) {
      if (d.url == "#") {
        if (d.url == this.hover) {
          this.hover = null;
        }
        this.hover = d.title;
      }
      if (d.url === "home") {
        this.router.push("/");
        this.open = false;
      }
    },
    btnRequestAccess() {
      this.router.push({ name: "register" });
    },
  },
  props: ["shadow"],
};
</script>

<style scoped>
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

/* .slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.1s;
  -webkit-transition-duration: 0.1s;
  -o-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -moz-transition-timing-function: ease-out;
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.slide-enter-to,
.slide-leave {
  max-height: 100%;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
} 




/* .slide2-enter-active {
  -moz-transition-duration: 1s;
  -webkit-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide2-leave-active {
  -moz-transition-duration: 0.1s;
  -webkit-transition-duration: 0.1s;
  -o-transition-duration: 0.1s;
  transition-duration: 0s;
  -moz-transition-timing-function: ease-out;
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.slid2-enter-to,
.slide2-leave {
  max-width: 100%;
  overflow: hidden;
}

.slide2-enter,
.slide2-leave-to {
  overflow: hidden;
  max-width: 0;
} */
</style>
  
const education = {
    state(){
        return {
            icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-9 h-9 text-blue-700 mr-4">
            <path d="M11.25 4.533A9.707 9.707 0 006 3a9.735 9.735 0 00-3.25.555.75.75 0 00-.5.707v14.25a.75.75 0 001 .707A8.237 8.237 0 016 18.75c1.995 0 3.823.707 5.25 1.886V4.533zM12.75 20.636A8.214 8.214 0 0118 18.75c.966 0 1.89.166 2.75.47a.75.75 0 001-.708V4.262a.75.75 0 00-.5-.707A9.735 9.735 0 0018 3a9.707 9.707 0 00-5.25 1.533v16.103z" />
          </svg>
          `,
            title:`Education`,
            subtitle:`Welcome to the world of blockchain technology, a revolutionary innovation that has the potential to reshape industries, redefine trust, and transform the way we interact with digital assets. In this comprehensive educational series, we will embark on a journey to demystify the complexities of blockchain, from its fundamental principles to its real-world applications.`,
            contents:[
                {
                    thumbnail:'edu1.webp',
                    title:'Crypto Custody Solutions: Need of the Hour as Crypto Adoption Gains Traction',
                    meta:'With the mass adoption of crypto assets underway, institutional investors, enterprises, and high net-worth',
                },
                {
                    thumbnail:'edu2.webp',
                    title:'Crypto Security: Simplifying complexities for an Individual',
                    meta:'With the growing adoption of cypto asset, it is becoming more and ',
                },
                {
                    thumbnail:'edu3.webp',
                    title:'Difference between Crypto Wallet and Exchange',
                    meta:'Sometimes it gets harder for new users to understand the fundamental difference between an exchange and a wallet',
                },
                {
                    thumbnail:'edu4.webp',
                    title:'What is blockchain infrastructure?',
                    meta:'Every single system and process relies on suitable infrastructure for its proper functioning. In the realm of technology, infrastructure plays ',
                },
                {
                    thumbnail:'edu5.webp',
                    title:'What are ERC-20 tokens?',
                    meta:'Ether, also called ETH, represents an entire ecosystem that empowers individuals to unleash their creativity and build a wide',
                },
                {
                    thumbnail:'edu6.webp',
                    title:'What is Proof Of Reserve?',
                    meta:'Following the FTX collapse, proof of reserves has become a prominent topic',
                },
                // {
                //     thumbnail:'edu7.webp',
                //     title:'What is mpc wallet?',
                //     meta:'Both MPC crypto wallet and multi-sig wallet are designed to ',
                // },
                {
                    thumbnail:'edu8.webp',
                    title:'What are BEP2 tokens?',
                    meta:'Navigating the Binance (BNB) ecosystem can be difficult. Whether you aim to trade, transact, or establish digital',
                },
                // {
                //     thumbnail:'edu9.webp',
                //     title:'What is a multisig wallet?',
                //     meta:'You bought your favorite crypto-currency, but where should you store it?',
                // },
            ]
        }
    }
}

export default education
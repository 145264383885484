const refill = {
  state() {
    return {
      hero: {
        img: "refill/refill-1.png",
        title: "Introducing Smart Refill Wallet",
        subtitle: [
          "Cryptocurrency platforms can now secure their assets and automate operations without any infrastructure alterations.",
          "This revolutionary solution is created by industry experts with the first-hand knowledge in building and running cryptocurrency exchanges.",
        ],
      },
      pair1: {
        img:"refill/refill-2.png",
        title: "What problem is it solving?",
        subtitle: [
          "The refilling of the withdrawal wallet is often a time-consuming process as the relevant team would need to monitor the liquidity balance and initiate transactions to refill them. Balancing this activity with ensuring just the right amount of assets are on withdrawal wallets to minimize security risk is a challenge. They need to ensure smooth operations while keeping the funds safe and maintaining enough liquidity to handle any withdrawal requests.",
          "Conventional wallet infrastructure fails to handle the immensely growing crypto adoption. Smart Refill Wallet is solving this problem with ease and efficiency.",
        ],
      },
      pair2:{
        img:"refill/refill-3.png",
        title:"How does it work?",
        subtitle:[
            'Smart Refill Wallet substitutes the warm wallet with a Refill solution, which serves as a bridge between the cold and the hot wallets. It specifies the number of refill transactions, the amount per refill, and the cooldown duration between refills.',
            'It continuously monitors and executes periodic refills in accordance with the parameters established by the platform’s refill policies.'
        ]
      },
      pair3:{
        img:"",
        benefit: [
            {
                image: "benefit-1.png",
                title: "Plug-and-Play",
                subtitle: "Ready-to-use platform with minor adjustments to the existing operational process and wallet infrastructure "
            },
            {
                image: "benefit-2.png",
                title: "Multi-Signature Wallets",
                subtitle: "This allows the platforms to distribute transaction signing rights to multiple users. Multi-party transaction authorization improves accountability and removes single points of failure"
            },
            {
                image: "benefit-3.png",
                title: "Whitelisting",
                subtitle: "Each Smart Refill Wallet is solely associated with one hot wallet, which practically excludes the option of transferring funds to any unverified or risky addresses"
            },
            {
                image: "benefit-4.png",
                title: "Seamless Refilling",
                subtitle: "The Smart Refill Wallet function automatically as per the predefined policies. The refill process will start once the declared parameters are met for refill frequency or the minimum wallet liquidity threshold value"
            },
        ],
      },
      pair4:{
        title:"Learn how Tennet can help you protect your digital assets",
        btn:{
            title:"Request Access",
            url:"#"
        }
      }
    };
  },
};

export default refill;

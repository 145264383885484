const solution = {
    state(){
        return {
            hero:{
                img:'solution/solution-1.png',
                title:'Instilling A New Digital Asset Custody Ecosystem For Enterprises',
                subtitle:'Tennet is enabling a robust unified workplace to optimize asset custody, asset security, compliance adherence and custom workflows for crypto-native teams.',
                btn:{
                    title:'Request Access',
                    url:'#'
                }
            }, 
            supported:{
                title:"Supported Protocol",
                images: [
                    {
                        title: "Bitcoin",
                        image: "btc.png"
                    },
                    {
                        title: "Ethereum",
                        image: "eth.png"
                    },
                    {
                        title: "Doge",
                        image: "doge.png"
                    },
                    {
                        title: "XRP",
                        image: "xrp.png"
                    },
                    {
                        title: "Cardano",
                        image: "ada.png"
                    },
                    {
                        title: "Solana", 
                        image: "sol.svg"
                    },
                    {
                        title: "Polkadot",
                        image: "dot.png"
                    },
                    {
                        title: "TRON",
                        image: "trx.png"
                    },
                    {
                        title: "Tezos",
                        image: "xtz.png"
                    },
                    {
                        title: "BSC",
                        image: "bnb.png"
                    },
                    {
                        title: "Polygon",
                        image: "matic.png"
                    },
                    // {
                    //     title: "ERC20",
                    //     image: "erc20.svg"
                    // },
                    // {
                    //     title: "IOTA",
                    //     image: "miota.png"
                    // },
                    // {
                    //     title: "USD Coin",
                    //     image: "usdc.png"
                    // },
                    // {
                    //     title: "The Sandbox",
                    //     image: "sand.png"
                    // },
                    // {
                    //     title: "Cosmos",
                    //     image: "atom.png"
                    // },
                    // {
                    //     title: "0x Prrotocol",
                    //     image: "zrx.png"
                    // },
                    // {
                    //     title: "Litecoin",
                    //     image: "ltc.png"
                    // },
                    // {
                    //     title: "Chainlink",
                    //     image: "link.png"
                    // },
                    // {
                    //     title: "Uniswap",
                    //     image: "uni.png"
                    // },
                    // {
                    //     title: "Stellar",
                    //     image: "xlm.png"
                    // },
                    // {
                    //     title: "Decentraland",
                    //     image: "mana.png"
                    // },
                    // {
                    //     title: "Enjin Coin",
                    //     image: "enj.png"
                    // },
                    // {
                    //     title: "UMA",
                    //     image: "uma.png"
                    // },
                    // {
                    //     title: "Basic Attention Token",
                    //     image: "bat.png"
                    // },
                    // {
                    //     title: "REN",
                    //     image: "ren.png"
                    // },
                    // {
                    //     title: "Qtum",
                    //     image: "qtum.png"
                    // },
                    // {
                    //     title: "Solar",
                    //     image: "ada.png"
                    // },
                    // {
                    //     title: "TrueUSD",
                    //     image: "tusd.png"
                    // },
                    // {
                    //     title: "Theta Network",
                    //     image: "theta.png"
                    // },
                    // {
                    //     title: "Synthetix",
                    //     image: "snx.png"
                    // },
                    // {
                    //     title: "Compound",
                    //     image: "comp.png"
                    // },
                    // {
                    //     title: "VeChain",
                    //     image: "vet.png"
                    // },
                    // {
                    //     title: "Status",
                    //     image: "snt.png"
                    // },
                    // {
                    //     title: "Maker",
                    //     image: "mkr.png"
                    // },
                    // {
                    //     title: "Tether",
                    //     image: "usdt.png"
                    // },
                    // {
                    //     title: "Storj",
                    //     image: "storj.png"
                    // },
                    // {
                    //     title: "Zilliqa",
                    //     image: "zil.png"
                    // },
                    // {
                    //     title: "OMG Network",
                    //     image: "omg.png"
                    // },
                    // {
                    //     title: "Harmony",
                    //     image: "one.png"
                    // },
                    // {
                    //     title: "iExec RLC",
                    //     image: "snx.png"
                    // },
                    // {
                    //     title: "Algorand",
                    //     image: "algo.png"
                    // },
                    // {
                    //     title: "EOS",
                    //     image: "eos.png"
                    // },
                    // {
                    //     title: "Wrapped Bitcoin",
                    //     image: "wbtc.png"
                    // },
                    // {
                    //     title: "Electroneum",
                    //     image: "etn.png"
                    // },
                    // {
                    //     title: "Avalanche",
                    //     image: "avax.png"
                    // },
                    // {
                    //     title: "Quant",
                    //     image: "qnt.png"
                    // },
                    // {
                    //     title: "Polymath",
                    //     image: "poly.png"
                    // },
                    // {
                    //     title: "DAI",
                    //     image: "dai.png"
                    // },
                    // {
                    //     title: "Loopring",
                    //     image: "lrc.png"
                    // },
                    // {
                    //     title: "Ethereum Classic",
                    //     image: "etc.png"
                    // },
                    // {
                    //     title: "Numeraire",
                    //     image: "nmr.png"
                    // },
                    // {
                    //     title: "Bitcoin cash",
                    //     image: "bch.png"
                    // },
                    // {
                    //     title: "yearn.finance",
                    //     image: "yfi.png"
                    // },
                    // {
                    //     title: "Neo",
                    //     image: "neo.png"
                    // },
                    // {
                    //     title: "Kusama",
                    //     image: "ksm.png"
                    // },
                    // {
                    //     title: "Golem",
                    //     image: "gnt.png"
                    // },
                    // {
                    //     title: "Ravencoin",
                    //     image: "rvn.png"
                    // },
                    // {
                    //     title: "Nem",
                    //     image: "xem.png"
                    // },
                    // {
                    //     title: "BitTorent",
                    //     image: "btt.png"
                    // },
                    // {
                    //     title: "ICON",
                    //     image: "icx.png"
                    // },
                    // {
                    //     title: "Serum",
                    //     image: "ser.png"
                    // },
                    // {
                    //     title: "Kyber Network",
                    //     image: "knc.png"
                    // },
                    // {
                    //     title: "Bitcoin Diamond",
                    //     image: "bcd.png"
                    // },
                    // {
                    //     title: "Ardor",
                    //     image: "ardr.png"
                    // },
                    // {
                    //     title: "Ontology",
                    //     image: "ont.png"
                    // },
                    // {
                    //     title: "Siacoin",
                    //     image: "sc.png"
                    // },
                    // {
                    //     title: "Band Protocol",
                    //     image: "band.png"
                    // },
                    // {
                    //     title: "PAX Gold",
                    //     image: "paxg.png"
                    // },
                    // {
                    //     title: "Ankr",
                    //     image: "snx.png"
                    // },
                    // {
                    //     title: "Digibyte",
                    //     image: "dgb.png"
                    // },
                    // {
                    //     title: "Ampleforth",
                    //     image: "ampl.png"
                    // },
                    // {
                    //     title: "BitcoinSV",
                    //     image: "bsv.png"
                    // },
                    // {
                    //     title: "DENT",
                    //     image: "dent.png"
                    // },
                    // {
                    //     title: "Request",
                    //     image: "req.png"
                    // },
                    // {
                    //     title: "Loom Network",
                    //     image: "loom.png"
                    // },
                    // {
                    //     title: "High Performance Blockchain",
                    //     image: "hpb.png"
                    // },
                    // {
                    //     title: "Balancer",
                    //     image: "bal.png"
                    // },
                    // {
                    //     title: "UNUS SED LEO",
                    //     image: "leo.png"
                    // },
                    // {
                    //     title: "Star Atlas",
                    //     image: "atlas.png"
                    // },
                    // {
                    //     title: "Raydium",
                    //     image: "ray.png"
                    // },
                ],
            },
            setup:{
                img:'solution-100.svg',
                title:"A Wallet Architecture Built For Efficient Transaction Processing",
                subtitle:"We work with you to redesign your crypto wallet architecture.",
                btn:{
                    title:"Request Access",
                    url:"#"
                },
                setup:[
                    {
                        img: 'setup-1.png',
                        title: 'Set up Wallet In 3 Easy Steps'
                    },
                    {
                        img: 'setup-2.png',
                        title: 'Remove Human Oriented Errors'
                    },
                    {
                        img: 'setup-3.png',
                        title: 'Lower Transaction Cost Through Smart Algorithms'
                    },
                    {
                        img: 'setup-4.png',
                        title: 'Get Interoperable and Seamless Wallet Connectivity'
                    },
                    {
                        img: 'setup-5.png',
                        title: 'Experience Customer-Centric Approach'
                    },
                ]
            },
            advance:{
                title:" Incorporate Tennet’s Advanced Solutions And Optimize The True Potential Of Your Assets",
                pairs:[
                    // {
                    //     img:'solution/solution-1a.png',
                    //     title:'Automation Capabilities Build Into Hot Wallets',
                    //     subtitle:'For high-frequency activity, continuous monitoring and balance checks in your Hot Wallets, use automation with Tennet’s Smart Refill Wallet',
                    //     reverse:0
                    // },
                    {
                        img:'solution/solution-2.svg',
                        title:'Impenetrable Key Management For Zero Key Leakage',
                        subtitle:'Tennet promotes self-custody through its hybrid integration of HSM, architecture to provide secure private key management',
                        reverse:0
                    },
                    {
                        img:'solution/solution-3.svg',
                        title:'Compliance and Regulation Proof Security Protocol',
                        subtitle:'Prioritizing security in collaboration with compliance for assets across the platform with ISO and SOC certifications, making Tennet most secure custody platform',
                        reverse:1
                    },
                    // {
                    //     img:'solution/solution-4.png',
                    //     title:'Advanced Multi-Entity Wallet For Scalability',
                    //     subtitle:'Highlighting decentralized custody by integrating Multi-Sig and MPC wallet functionalities to defeat single point-of-failure through multi-signature and multi-person for layered transaction authentication.',
                    //     reverse:0
                    // },
                    {
                        img:'solution/solution-5.png',
                        title:'On-Demand Concierge and Onboarding Services',
                        subtitle:'Meet our team of custodianship to ease your journey into the world of Tennet, explore and select the best set of solutions for your custom needs with dedicated account managers and a comprehensive knowledge base',
                        reverse:0
                    },
                    // {
                    //     img:'solution/solution-6.png',
                    //     title:'Insurance Coverage Of Over $50 Million',
                    //     subtitle:'We have set up an insurance coverage of $50 million for our partner projects to add an extra layer of asset protection, underwritten by global specialty insurers earmarked to cover everything from hacks to key misplacement',
                    //     reverse:0
                    // },
                ]
            },
            feature:{
                title:"Unlock A Practical Feature Stack and Scale Without Fear",
                btn:{
                    title:"Request Access",
                    url:"#"
                },
                features:[
                    {
                        icon: "feature-1.png",
                        title: "Multi-Chain Asset Support",
                        subtitle: "Import your EVM and Non-EVM assets with a unified interface"
                    },
                    {
                        icon: "feature-2.png",
                        title: "Save Upto 15% on Network Fee",
                        subtitle: "Settle transactions with seemingly reduced network fees for major EVM chains"
                    },
                    {
                        icon: "feature-3.png",
                        title: "Check Your Protocols CCSS Compliance",
                        subtitle: "Investigate and authenticate your systems with our checklist to be CCSS compliant"
                    },
                    {
                        icon: "feature-4.png",
                        title: "Allocate Signatory Permissions Variably",
                        subtitle: "Define roles and permissions for various users based on their functions"
                    },
                    {
                        icon: "feature-5.png",
                        title: "Safeguard Finality By Transaction Shield",
                        subtitle: "Verifiable transactions by Tennetsigner to ensure compliance set up by your organization"
                    },
                    {
                        icon: "feature-6.png",
                        title: "Transaction Reporting and Accounting",
                        subtitle: "Track every transaction made through the wallets imported on Tennetand make accounting for digital assets seamless"
                    },
                    {
                        icon: "feature-7.png",
                        title: "Mobile First Custody Approach",
                        subtitle: "Access your treasury, sign transaction, and create and approve policies on the go with TennetVaults mobile application"
                    },
                ]
            },
            wallet:{
                title:"Group Wallets protect you in three ways",
                wallets:[
                    {
                        image: "wallet-1.png",
                        title: "Multi-Approval",
                        subtitle: "A three-step approval process where all users must approve simultaneously in order for the process to be marked as Approved. If any user rejects, the process is immediately marked as rejected."
                    },
                    {
                        image: "wallet-2.png",
                        title: "Multi-person",
                        subtitle: "1 private key that can be accessed with authorization from multiple parties"
                    },
                    {
                        image: "wallet-3.png",
                        title: "Multi-location",
                        subtitle: "Each key and its backup are stored at a different location. A thief or natural disaster will likely steal or destroy only one key at a time. This makes it unlikely that you’ll lose more than one key at a time."
                    },
                ]
            }

        }
    }
}

export default solution
<template>
    <div v-if="open == true" @click="open=!open" class="">
        <div class="opacity-30 flex justify-center items-center flex-col w-screen h-screen absolute bg-indigo-300 top-0 left-0 z-100"></div>
        <div class="opacity-100 outline-purple-700 flex flex-col bg-white max-h-max max-w-xl p-4 rounded-md shadow-md z-110 absolute m-auto left-0 right-0 top-0 bottom-0 ">
            <h1 class="text-zinc-800 text-2xl font-bold my-4">Hapus User</h1>
            <p class="text-zinc-600 text-md">Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore deleniti eius autem accusantium rem dolorum minus voluptates quisquam quam porro?</p>
            <div class="flex justify-end space-x-4">
                <AsyncButton2 :title="'Batal'" @click="console.log('oke')"/>
                <AsyncButton2 :title="'Oke'" @click="console.log('oke')"/>
            </div>
        </div>
    </div>
</template>

<script>
import AsyncButton2 from '../button/AsyncButton2.vue';

export default {
    data() {
        return {
            open: false
        };
    },
    components: { AsyncButton2 }
}
</script>